import { useEffect } from "react";
import isInsideIframe from "./utils/isInsideIframe";
import { LogsProvider } from "./hooks/useLogs";
import LogsContainer from "./components/ui/LogsContainer";
import LoadingContainer from "./components/LoadingConitainer";
import OrganizationScreen from "./components/OrganizationScreen";
import DefaultSlideShowScreen from "./components/DefaultSlideShowScreen"
import { AuthProvider, useAuth } from "./hooks/useAuth";
import ErrorBoundary from "./components/ErrorBoundary";
import { gzLog } from "./utils/gzAnalytics";
import { FullScreenProvider } from "./hooks/useFullScreen/full-screen-provider";
import ExitPopupDialog from "./components/ExitPopupDialog.tsx";

function App() {
  useEffect(() => {
    if (isInsideIframe()) {
      setTimeout(() => {
        window.parent.postMessage("app_loaded", "*");
      }, 100);
    }
  }, []);

  useEffect(() => {
    const { outerWidth, outerHeight, innerWidth, innerHeight, screen: { width, height, availWidth, availHeight, orientation: { type, angle } } } = window
    gzLog({
      // @ts-ignore
      name: "DeviceSizeInfo", eventtype: "ScreenSizeEvent",
      payload: {
        outerWidth, outerHeight, innerWidth, innerHeight,
        screen: JSON.stringify({ width, height, availWidth, availHeight, orientation: { type, angle } }),
      },
    })
  }, [])

  return (
    <FullScreenProvider>
      <Main />
    </FullScreenProvider>
  );
}

function Main() {
  return <LogsProvider
    targetSequence={[
      "ArrowLeft",
      "ArrowRight",
      "ArrowRight",
      "ArrowLeft",
      "ArrowRight",
    ]}
  >
    <div
      aria-hidden="true"
      className="relative"
      tabIndex={-1}
      id="rootElement"
    >
      <ErrorBoundary>
        <div aria-hidden="true" className="absolute">
          <AuthProvider>
            <Home />
          </AuthProvider>
        </div>
      </ErrorBoundary>
    </div>
  </LogsProvider>
}

function Home() {
  const { screenInfoFetchStatus, screenInfo, isPreviewMode } = useAuth();

  return (
    <div aria-hidden="true" className="h-screen w-screen">
      {screenInfoFetchStatus !== "success" ? (
        <LoadingContainer />
      ) : (
        <>
          {screenInfo !== null && !isPreviewMode ? (
            <ExitPopupDialog hasError={false}>
              <OrganizationScreen initialScreenInfo={screenInfo} key={screenInfo.id} />
            </ExitPopupDialog>
          ) : (
            <DefaultSlideShowScreen />
          )}
        </>
      )}

      <LogsContainer />
    </div>
  );
}

export default App;
