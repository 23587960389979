import { useState, useEffect, useCallback, useLayoutEffect } from 'react'
import { Button } from "../ui/button"
import { Volume2, VolumeX, Play, Pause } from "lucide-react"
import { cn } from '../../lib/utils'
import { MusicInfo } from '../../../types/music'
import { MusicAction } from '../../hooks/useScreen/types'

type MusicControllerListeners = {
  onPlay?: (currentTrack: MusicInfo) => void
  onPause?: (currentTrack: MusicInfo) => void
  onVolumeChange?: (currentTrack: MusicInfo, volume: number) => void
  onNext?: (currentTrack: MusicInfo) => void
  onPrev?: (currentTrack: MusicInfo) => void
  onTrackChange?: (currentTrack: MusicInfo | null) => void
  action?: MusicAction
}

var hasInteracted = false
var isFirstInteraction = true

export function MusicController({
  className,
  musicInfos: playlists,
  onVolumeChange,
  onPlay,
  onPause,
  onNext,
  onPrev,
  onTrackChange,
  action,
  ...props
}: Omit<React.ComponentPropsWithoutRef<"div">, keyof MusicControllerListeners> & {
  musicInfos: MusicInfo[]
} & MusicControllerListeners) {
  const [isPlaying, setIsPlaying] = useState(hasInteracted)
  const [volume, setVolume] = useState(1)
  const [currentPlaylistIndex, _setCurrentPlaylistIndex] = useState(0)
  const [isDisabled, setIsDisabled] = useState(false)

  const currentTrack = playlists[currentPlaylistIndex] || null

  useEffect(() => {
    console.log(action)
    switch (action) {
      case 'pause':
        setIsPlaying(false)
        break
      case 'resume':
        setIsPlaying(true)
        break
      case 'disable':
        setIsPlaying(false)
        setIsDisabled(true)
        break
      case 'enable':
        setIsDisabled(false)
        break
    }
  }, [action])

  useEffect(() => {
    onTrackChange?.(currentTrack)
  }, [currentTrack])

  useLayoutEffect(() => {
    const handler = () => {
      if (isFirstInteraction) {
        isFirstInteraction = false
        hasInteracted = true
        setIsPlaying(true)
      }
    }
    window.addEventListener("keydown", handler);
    window.addEventListener("click", handler)
    window.addEventListener("scroll", handler)

    return () => {
      window.removeEventListener("click", handler)
      window.removeEventListener("scroll", handler)
    }
  }, [])

  useEffect(() => {
    if (currentTrack)
      onVolumeChange?.(currentTrack, volume)
  }, [volume])

  useEffect(() => {
    if (currentTrack) {
      if (isPlaying) onPlay?.(currentTrack)
      else onPause?.(currentTrack)
    }
  }, [isPlaying])

  const handlePlayPause = () => {
    setIsPlaying(prev => !prev)
  }

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    switch (event.code) {
      case 'Space':
        handlePlayPause()
        break
    }
  }, [playlists])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [handleKeyDown])

  if (!currentTrack || isDisabled) return null

  return (
    <div {...props} className={cn(className, "transform -translate-x-1/2 w-fit bg-background border border-border rounded-lg shadow-lg p-2 overflow-hidden transition-all duration-300 ease-in-out hover:shadow-2xl")}>
      <div className="flex items-center relative overflow-hidden z-10">
        <div className="flex items-center space-x-1">
          <Button variant="ghost" size="icon" className="h-8 w-8 transition-transform duration-200 hover:scale-110" onClick={handlePlayPause}>
            {isPlaying ? <Pause className="h-4 w-4" /> : <Play className="h-4 w-4" />}
          </Button>
          <Button variant="ghost" size="icon" className="h-8 w-8 transition-transform duration-200 hover:scale-110" onClick={() => {
            setVolume(v => v === 0 ? 1 : 0)
          }}>
            {volume === 1 ? <Volume2 className="h-4 w-4" /> : <VolumeX className="h-4 w-4" />}
          </Button>
        </div>
      </div>
    </div>
  )
}
